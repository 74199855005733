import Browser from 'components/Browser';
import Still from 'components/Still';
import Video from 'components/Video';
import PostColumn from 'components/PostColumn';
import Markdown from 'components/Markdown';
import List from 'components/List';
import { Element as ListItem } from 'components/ListItem';
import Stack from 'components/Stack';
import Link from 'components/Link';
import imageStillProductPlanning from 'assets/images/product/still/planning.jpg';
import imageStillProductV1Concept from 'assets/images/product/still/concept-v1.jpg';
import imageStillProductV1Menu from 'assets/images/product/still/concept-v1-menu.jpg';
import videoProductMP4Interface from 'assets/video/product/v2-demo.mp4';
import * as React from 'react';
export default {
  Browser,
  Still,
  Video,
  PostColumn,
  Markdown,
  List,
  ListItem,
  Stack,
  Link,
  imageStillProductPlanning,
  imageStillProductV1Concept,
  imageStillProductV1Menu,
  videoProductMP4Interface,
  React
};