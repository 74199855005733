import Gallery from 'components/Gallery';
import GalleryItem from 'components/GalleryItem';
import Browser from 'components/Browser';
import Device from 'components/Device';
import Video from 'components/Video';
import Still from 'components/Still';
import PostColumn from 'components/PostColumn';
import Markdown from 'components/Markdown';
import List from 'components/List';
import { Element as ListItem } from 'components/ListItem';
import Stack from 'components/Stack';
import imageDesktopReddsInterface from 'assets/images/redds/desktop/interface.jpg';
import imageStillReddsBTS2 from 'assets/images/redds/still/bts2.jpg';
import imageStillReddsSystemDiagram from 'assets/images/redds/still/system-diagram.gif';
import imageStillReddsUserFlow from 'assets/images/redds/still/user-flow.gif';
import videoReddsMP4Interface from 'assets/video/redds/interface.mp4';
import videoReddsWebmInterface from 'assets/video/redds/interface.webm';
import videoReddsMP4Trailer from 'assets/video/redds/trailer.mp4';
import videoReddsStillTrailer from 'assets/video/redds/trailer.jpg';
import * as React from 'react';
export default {
  Gallery,
  GalleryItem,
  Browser,
  Device,
  Video,
  Still,
  PostColumn,
  Markdown,
  List,
  ListItem,
  Stack,
  imageDesktopReddsInterface,
  imageStillReddsBTS2,
  imageStillReddsSystemDiagram,
  imageStillReddsUserFlow,
  videoReddsMP4Interface,
  videoReddsWebmInterface,
  videoReddsMP4Trailer,
  videoReddsStillTrailer,
  React
};