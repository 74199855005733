import Gallery from 'components/Gallery';
import GalleryItem from 'components/GalleryItem';
import Browser from 'components/Browser';
import Device from 'components/Device';
import Still from 'components/Still';
import PostColumn from 'components/PostColumn';
import Markdown from 'components/Markdown';
import List from 'components/List';
import { Element as ListItem } from 'components/ListItem';
import { TertiaryTitle } from 'components/Heading';
import Stack from 'components/Stack';
import Link from 'components/Link';
import imageMobileV1 from 'assets/images/remedy-plan/mobile/v1-blog.gif';
import imageMobileV2 from 'assets/images/remedy-plan/mobile/v2-pipeline.gif';
import imageDesktopV2Candidates from 'assets/images/remedy-plan/desktop/v2-candidates.gif';
import imageDesktopV2Home from 'assets/images/remedy-plan/desktop/v2-home.gif';
import imageDesktopV2Team from 'assets/images/remedy-plan/desktop/v2-team.gif';
import imageDesktopV1Home from 'assets/images/remedy-plan/desktop/v1-home.gif';
import imageDesktopIndiegogo from 'assets/images/remedy-plan/desktop/indiegogo.gif';
import imageDesktopV1Full from 'assets/images/remedy-plan/desktop/v1-full.gif';
import imageDesktopV2Full from 'assets/images/remedy-plan/desktop/v2-full.jpg';
import imageStillIdentity from 'assets/images/remedy-plan/still/identity.gif';
import * as React from 'react';
export default {
  Gallery,
  GalleryItem,
  Browser,
  Device,
  Still,
  PostColumn,
  Markdown,
  List,
  ListItem,
  TertiaryTitle,
  Stack,
  Link,
  imageMobileV1,
  imageMobileV2,
  imageDesktopV2Candidates,
  imageDesktopV2Home,
  imageDesktopV2Team,
  imageDesktopV1Home,
  imageDesktopIndiegogo,
  imageDesktopV1Full,
  imageDesktopV2Full,
  imageStillIdentity,
  React
};