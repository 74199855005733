import { Flex } from 'rebass';
import Gallery from 'components/Gallery';
import GalleryItem from 'components/GalleryItem';
import Browser from 'components/Browser';
import Device from 'components/Device';
import Still from 'components/Still';
import Video from 'components/Video';
import PostColumn from 'components/PostColumn';
import Markdown from 'components/Markdown';
import List from 'components/List';
import { Element as ListItem } from 'components/ListItem';
import Stack from 'components/Stack';
import Link from 'components/Link';
import imageDesktopCriterionHome from 'assets/images/criterion/desktop/home.jpg';
import imageDesktopCriterionFilm from 'assets/images/criterion/desktop/film.jpg';
import imageDesktopCriterionCollection from 'assets/images/criterion/desktop/collection.jpg';
import imageDesktopCriterionFlashSale from 'assets/images/criterion/desktop/flash-sale.jpg';
import imageMobileCriterionHome from 'assets/images/criterion/mobile/home.jpg';
import imageMobileCriterionArticle from 'assets/images/criterion/mobile/article.jpg';
import videoCriterionMP4Slideshow from 'assets/video/criterion/slideshow.mp4';
import * as React from 'react';
export default {
  Flex,
  Gallery,
  GalleryItem,
  Browser,
  Device,
  Still,
  Video,
  PostColumn,
  Markdown,
  List,
  ListItem,
  Stack,
  Link,
  imageDesktopCriterionHome,
  imageDesktopCriterionFilm,
  imageDesktopCriterionCollection,
  imageDesktopCriterionFlashSale,
  imageMobileCriterionHome,
  imageMobileCriterionArticle,
  videoCriterionMP4Slideshow,
  React
};