import Gallery from 'components/Gallery';
import GalleryItem from 'components/GalleryItem';
import Browser from 'components/Browser';
import Still from 'components/Still';
import PostColumn from 'components/PostColumn';
import Markdown from 'components/Markdown';
import List from 'components/List';
import Link from 'components/Link';
import { Element as ListItem } from 'components/ListItem';
import Stack from 'components/Stack';
import imageDesktopNGHome from 'assets/images/natgeo/desktop/home.jpg';
import imageDesktopNGPOD from 'assets/images/natgeo/desktop/pod.jpg';
import imageDesktopNGNews from 'assets/images/natgeo/desktop/news.jpg';
import imageDesktopNGApple from 'assets/images/natgeo/desktop/apple.jpg';
import imageDesktopNGLayoutManager from 'assets/images/natgeo/desktop/layoutmanager.jpg';
import imageStillNGJobs from 'assets/images/natgeo/still/jobs.jpg';
import * as React from 'react';
export default {
  Gallery,
  GalleryItem,
  Browser,
  Still,
  PostColumn,
  Markdown,
  List,
  Link,
  ListItem,
  Stack,
  imageDesktopNGHome,
  imageDesktopNGPOD,
  imageDesktopNGNews,
  imageDesktopNGApple,
  imageDesktopNGLayoutManager,
  imageStillNGJobs,
  React
};